<template>

    <div>         
        <div class="nav-symbol aboutnav" :class="{'mousedown': mousePressed}">
            <svg width="365px" height="707px" viewBox="0 0 365 707" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>About</title>
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="01_Start" transform="translate(-1075.000000, -317.000000)" fill="#003FFF" fill-rule="nonzero">
                        <g id="Hintergrundelemente" transform="translate(0.000000, -0.083832)">
                            <router-link to="/about" id="aboutlink">   
                                <g id="Group" transform="translate(-243.000000, 0.000000)">
                                    <g id="About" transform="translate(1318.000000, 317.083832)" fill="#003FFF">
                                        <path d="M365.00072,0.993532 L364.991,707 L0,707 C201.73806,607.55415 323.40496,372.218661 365.00072,0.993532 Z"></path>
                                    </g>
                                </g>
                            </router-link>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
        <div v-if="show" class="about-nav navi">
            <p>About</p>
        </div>
    </div>
    

</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'AboutNav',
    components: {

    },
    data () {
        return {
          display: false
        }
    },
    computed: {
      ...mapGetters([
                'mousePressed'
            ]),
        show: function() {
            if ( this.display && !this.mousePressed ) {
                return true
            } else {
                return false
            }
        }
    },
    mounted() {  
        const link = document.querySelector('#aboutlink')
        link.addEventListener('mouseover', () => {
            this.display = true
        })

        link.addEventListener('mouseout', () => {
            this.display = false
        })
    },
    destroyed() {
  
    }
}
</script>

<style lang="scss">

.aboutnav {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 10;

    svg {
        right: 0;
        height: 70vh;
        width: auto;
    }
}

</style>
