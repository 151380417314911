<template>
    <div class="mobile-header">
        <Mobilenav/>
        <div id="logo">
          <h1>
            <router-link to="/" :class="{'text-white': textWhite}">  
              NG<br v-if="mobile" />
            </router-link>
            <span v-if="$route.path =='/projekte' && !navOpen" class="text-rot">Projects</span>
            <span v-if="$route.path =='/about' && !navOpen" class="text-weiss">About</span>
            <span v-if="$route.path =='/contact' && !navOpen" class="text-weiss">Contact</span>
            <span v-if="$route.path =='/mark-gallery' && !navOpen" class="text-weiss sel-weiss">Gallery</span>
            <span v-if="$route.path =='/canvas' && !navOpen" class="text-weiss sel-weiss">Canvas</span>
          </h1>
        </div><!--#logo--> 
        <div id="mark" v-if="$route.path !='/canvas' && $route.path !='/mark-gallery'">
            <img src="../assets/Mark NG Illustrationen.svg">
        </div>
        <div id="kontakt" v-if="$route.path !='/contact'">
            <img src="../assets/Kontakt Nikolai Gust Grafikdesign Dortmund.svg">
        </div>
        <div id="about" v-if="$route.path !='/about'">
            <img src="../assets/ueber ng grafikdesign dortmund.svg">
        </div>
        <div id="projects" v-if="$route.path !='/projekte'">
            <img src="../assets/Projects Illustration.svg">
        </div>
       
    </div><!--.mobile-header (root)-->
    
</template>

<script>
import Mobilenav from '@/components/Mobilenav.vue'
import { mapGetters } from 'vuex'

export default {
    props: ['mobile'],
    name: 'MobileHeader',
    components: {
        Mobilenav
    },
    data () {
    return {
        name: ''
    }
  },
    computed: {
        textWhite: function () {
            if (this.$route.path =='/contact') {
                return true
            } else {
                return false
            }
        },
        volleBreite: function () {
          if (this.$route.path =='/canvas') {
                return true
            } else {
                return false
            }
        },
        ...mapGetters([
                'navOpen'
            ])
    },
    watch:{
    $route () {
       // this.$store.commit('setOpen', false )
    }
} 
}
</script>

<style lang="scss">


.mobile-header {
    font-family: 'LeagueGothic', sans-serif;
    font-style: italic;

    #mark {
        position: fixed;
        top: 0;
        left: 30vw;
        z-index: 10;
        img {
            width: 40vw;
            height: auto;
        }
    }

    #kontakt {
        position: fixed;
        bottom: 0;
        left: 4vw;
        z-index: 10;
        img {
            width: 40vw;
            height: auto;
        }
    }

    #about {
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 10;
        img {
            width: 55vw;
            height: auto;
        }
    }

    #projects {
        position: fixed;
        top: 15vh;
        left: -40vw;
        z-index: 10;
        img {
            width: 60vw;
            height: auto;
        }
    }

    #logo {
        position: fixed;
    }
}



   
</style>