<template>

    <div> 
        <div class="projects nav-symbol" :class="{'mousedown': mousePressed}">
            <svg width="400px" height="400px" viewBox="0 0 1 400" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Projects</title>
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="01_Start" transform="translate(0.000000, 0)" fill="#FF466C" fill-rule="nonzero">
                        <g id="Hintergrundelemente" transform="translate(0.000000, 0)">
                            <router-link to="/projekte" id="projektlink"> 
                                <g id="Group" transform="translate(-200, 0.000000)">
                                    <g id="Projects" transform="translate(0.000000, 0)" fill="#FF466C">
                                        <circle cx="200" cy="200" r="200"></circle>
                                    </g>
                                </g>
                            </router-link>
                        </g>
                    </g>
                </g>
            </svg>  
        </div>
        <div v-if="show" class="projekte-nav navi">
            <p>Projects</p>
        </div>
    </div>


</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Kreis',
    components: {

    },
    data () {
        return {
          display: false
        }
    },
    computed: {
      ...mapGetters([
                'mousePressed'
            ]),
        show: function() {
            if ( this.display && !this.mousePressed ) {
                return true
            } else {
                return false
            }
        }
    },
    mounted() {  
        const link = document.querySelector('#projektlink')
        link.addEventListener('mouseover', () => {
            this.display = true
        })

        link.addEventListener('mouseleave', () => {
            this.display = false
        })
    },
    destroyed() {

    }
}
</script>

<style lang="scss">

.projects {
    position: fixed;
    top: 15vh;
    left: -24vh;
    z-index: 100;
    
    svg {
        height: 40vh;
        width: auto;   
    }
}


</style>
