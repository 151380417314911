<template>
    <transition name="navrollo" appear>
        <div class="impressum" v-if="impressum">
            <div class="closer" @click="$store.commit('impressumOff')">
                <div class="twist"></div>
                <div class="twisted"></div>
            </div>
            <h2 class="logo">{{heading}}</h2>
            <b-container>
                <b-row>
                    <b-col>
                        <div v-html="content" class="content"></div>
                    </b-col>
                </b-row>
            </b-container>
            <h2></h2>
        </div>
    </transition>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
    data () {
        return {
           impressumContent: []
        }
    },
     computed: {
            ...mapGetters([
                'impressum'
            ]),
      heading: function () {
        if (this.impressumContent[0]) {
          return this.impressumContent[0].title.rendered
        } else {
          return ""
        }
      },
      content: function () {
        if (this.impressumContent[0]) {
          return this.impressumContent[0].content.rendered
        } else {
          return ""
        }
      }

        },
    created () {
        axios.get('https://wordpress.nikolaigust.de/wp-json/wp/v2/pages?slug=impressum' )
        .then(res => {          
            this.impressumContent = res.data
        })
    }
    
}

</script>
<style lang="scss">
    .impressum {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100vw;
        background-color: #fff;
        z-index: 100000;
        padding: 50px 0;
        //margin-top: 80px;
        overflow-y: scroll;

        .content {
            
        }

        h2.logo {
            z-index: 1101;
            position: absolute;
            font-size: 11vh;
            line-height: 11vh;
            top: 20px;
            left: 20px;
            margin: 0;
            text-transform: uppercase;
        }

        h2 {
            font-family: LeagueGothic;
            font-size: 70px;
            margin: 0.5em 0;
        }

        p {
            font-size: 18px;
            line-height: 27px;
            margin-bottom: 1em;

            &.adresse {
                 font-size: 57px;
                 line-height: 85px;
            }
        }

        a, a:visited {
            color: #000;
        }

         @media screen and (max-width: 767px) {
            h1, h2 {
                font-size: 40px;
                margin: 0.5em 0;
            }

            h2.logo {
                margin-top: 85px;
            }

            p {
                font-size: 12px;
                line-height: 18px;
                margin-bottom: 1em;

                &.adresse {
                    font-size: 30px;
                    line-height: 45px;
                }
            }
        }

        .closer {
        height: 45px;
        position: fixed;
        right: 20px;
        top: 40px;
        z-index: 1102;

        &:hover {
            cursor: pointer;
        }

            div {
                width: 80px;
                height: 4px;
                transition: all 0.3s;
                background-color: #000;
                transform:  rotate(-225deg);
                background-color: #000;
                &.twisted {        
                    transform: translate( 0, -4px ) rotate(225deg);
                    transform-origin: 50% 50%;
                }
            }
        }
    }
</style>
