<template>
  <div id="app" :class="selection">
    <Header v-if="!mobile && !touch"/>
    <MobileHeader v-if="mobile || touch" :mobile="mobile"/>
    <transition :name="name">
      <router-view :mobile="mobile"/>
    </transition>
    <Footer/>
    <Impressum/>
    <Mobilecheck v-if="landscape"/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import MobileHeader from '@/components/MobileHeader.vue'
import Footer from '@/components/Footer.vue'
import Impressum from '@/components/Impressum.vue'
import Mobilecheck from '@/components/Mobilecheck.vue'

//import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    Header,
    MobileHeader,
    Footer,
    Impressum,
    Mobilecheck
  },
  data () {
    return {
        name: '',
        mobile: window.innerWidth <=767,
        landscape: false
    }
  },
  computed: {
    selection: function () {
      if (this.$route.path == '/projekte') {
          return 'sel-blau'
      } else if (this.$route.path == '/about') {
          return 'sel-gelb'
      } else if (this.$route.path == '/canvas' || this.$route.path == '/mark-gallery') {
          return 'sel-weiss'
      } else if (this.$route.path == '/contact') {
          return 'sel-gelb'
      } else {
          return 'sel-blau'
      }
    },
    touch: function () {
      if ("ontouchstart" in document.documentElement)
        {
          return true
        }
        else
        {
          return false
        }
      }
  },
  methods: {
    isLandscape: function () {
      const width = window.innerWidth
      const height = window.innerHeight
      const ratio = width / height
      if (ratio >= 3 && window.innerWidth >= 768) {
        this.landscape = true
      } else {
        this.landscape = false
      }
    }
  },
  created () {
      addEventListener('resize', () => {
      this.mobile = window.innerWidth <= 767
      this.isLandscape()
    })

    this.isLandscape()
    
  },
  watch: {
      '$route' (to) {
        if (to.path == '/about') {
          this.name = 'rtol'
        } else if (to.path == '/contact'){
          this.name = 'btot'
        } else if (to.path == '/projekte'){
          this.name = 'ltor'
        } else if (to.path == '/canvas'){
          this.name = 'ttob'
        } else if (to.path == '/mark-gallery'){
          this.name = 'none'
        } else if (to.path == '/'){
          this.name = 'ltor'
        }
      }
    }
}
</script>

<style lang="scss">
#app {
        position: relative;
        height: 100vh;
        max-height: 100vh;
        overflow: hidden;
        @media screen and (max-width: 767px) {
          overflow-y: scroll;
          overflow-x: hidden;
        }

//Selection 
  &.sel-blau {
    ::-moz-selection { /* Code for Firefox */
        background: #003fff;
        }

    ::selection {
        background: #003fff;
    }
  }

  &.sel-rot {
    ::-moz-selection { /* Code for Firefox */
        background: rgba(255, 70, 108, 1);
        }

    ::selection {
        background: rgba(255, 70, 108, 1);
    }
  }

  &.sel-weiss {
    ::-moz-selection { /* Code for Firefox */
        background:#fff;
        }

    ::selection {
        background: #fff;
    }
  }

  &.sel-gelb  {
    ::-moz-selection { /* Code for Firefox */
        background:#FFFF9F;
        }

    ::selection {
        background: #FFFF9F;
    }
  }
    }

</style>
