<template>

    <div>   
        <div class="nav-symbol marknav" :class="{'mousedown': mousePressed}">         
             
                <svg width="294px" height="460px" viewBox="0 0 294 460" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Mark</title>
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="01_Start" transform="translate(-674.000000, 0.000000)" fill-rule="nonzero">
                            <g id="Hintergrundelemente" transform="translate(0.000000, -0.083832)">
                                <router-link to="/canvas" id="marklink">   
                                    <g id="Group" transform="translate(-243.000000, 0.000000)">
                                        <g id="Marksub" transform="translate(917.000000, 0.083832)" fill="#FFFF9F">
                                            <polygon points="0 0 294 460 246.90316 1.4173386e-15"></polygon>
                                        </g>
                                    </g>
                                </router-link>
                            </g>
                        </g>
                    </g>
                </svg>
            

        </div>
        <div v-if="show" class="mark-nav navi">
            <p>Canvas</p>
        </div>
    </div>
        
    

</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'MarkNav',
    components: {

    },
    data () {
        return {
            display: false
        }
    },
    computed: {
      ...mapGetters([
                'mousePressed'
            ]),
        show: function() {
            if ( this.display && !this.mousePressed ) {
                return true
            } else {
                return false
            }
        }
    },
    mounted() {  
        const link = document.querySelector('#marklink')
        link.addEventListener('mouseover', () => {
            this.display = true
        })

        link.addEventListener('mouseleave', () => {
            this.display = false
        })
    },
    destroyed() {

    }
}
</script>

<style lang="scss">

.marknav {
        position: fixed;
        top: 0;
        left: 47vw;
        z-index: 100;
        
        svg {
            height: 40vh;
            width: auto; 
        }
    }

.mark-nav {
    z-index: 1050;
}





</style>
