<template>
    <transition name="navrollo" appear>
        <div class="mobile-check">
            <b-container>
                <b-row>
                    <b-col>
                        <h2>Oooops... This strange browser format is making me dizzy! Please try resizing your browser to a more common format or twist your mobile device to portrait view.</h2>
                    </b-col>
                </b-row>
            </b-container>
            <h2></h2>
        </div>
    </transition>
</template>

<script>

export default {
    props: ['mobileCheck'],
    name: 'Mobilecheck',
    data () {
        return {
          
        }
    }   
}

</script>
<style lang="scss">
    .mobile-check {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100vw;
        background-color: #fff;
        z-index: 1100;
        padding: 50px 0;
        margin-top: 0;
        overflow-y: scroll;

        h2 {
            margin-top: 20vh;
            font-family: LeagueGothic;
            font-size: 40px;
            margin: 0.5em 0;
        }
    }
</style>
