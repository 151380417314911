<template>
    <div class="mobile-nav">

        <div class="toggler" @click="toggleNav">
            <div class="rot" v-bind:class="{ hidden : navOpen }"></div>
            <div class="blau" v-bind:class="{ twisted : navOpen }"></div>
            <div class="gelb" v-bind:class="{ twisted : navOpen }"></div>
            <div class="schwarz" v-bind:class="{ hidden : navOpen }"></div> 
        </div><!--toggler--> 
        
    <transition name="navrollo">
        <div id="mobile-nav-list" v-if="navOpen">
            <ul @click="toggleNav">
                <li class="projects-mobile"><router-link to="/projekte">Projects</router-link></li>
                <li class="about-mobile"><router-link to="/about">About</router-link></li>
                <li class="mark-mobile"><router-link to="/canvas">Canvas</router-link></li>
                <li class="contact-mobile"><router-link to="/contact">Contact</router-link></li>
            </ul>
        </div><!--#mobiel-nav-list-->
    </transition>
    </div><!--mobile-nav-->
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Mobilenav',
    components: {
       
    },
    data () {
        return {
             open: false
        }
    },
    computed: {
      ...mapGetters([
                'navOpen'
            ])
    },
    methods: {
        toggleNav: function() {
            this.$store.commit('setOpen', !this.navOpen )
        }
    }
}
</script>

<style lang="scss">

.mobile-nav {
    z-index: 1100;
    position: fixed;
    
    .hidden {
        display: none;
    }

    .toggler {
        height: 45px;
        position: fixed;
        right: 20px;
        top: 20px;
        z-index: 778;

        div {
                width: 45px;
                height: 2px;
                transition: all 0.3s;
        }

        .rot {
            background-color: #ff466c;
            margin-bottom: 12px;
        }

        .blau {
            margin-bottom: 12px;
            background-color: #003fff;

            &.twisted {
                transform:  translate( 0px, 21px ) rotate(-225deg) scale(1.1);
                background-color: #000;
            }
        }

        .gelb {
            margin-bottom: 12px;
            background-color: #ffff9f;

            &.twisted {
                
                transform: translate( 0, 7px )rotate(225deg) scale(1.1);
                transform-origin: 50% 50%;
                background-color: #000;
            }
        }

        .schwarz {
            background-color: #000;
        }
    }

    #mobile-nav-list {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        background-color: #fff;
        text-align: center;
        font-family: 'LeagueGothic', sans-serif;
        font-style: italic;
        text-transform: uppercase;
        font-size: 23vw;
        line-height: 24vw;
        height: 100vh;
        overflow-y: scroll;

        ul {
            position: relative;
            padding: 0;
            top: 110px;
            list-style: none;
            background-color: #fff;
        }

        .projects-mobile {
            a, a:visited {
                color: #ff466c;
            }
        }

        .about-mobile {
            a, a:visited {
                color: #003fff;
            }     
        }

        .mark-mobile {
            a, a:visited {
                color: #ffff9f;
            }      
        }

        .contact-mobile {
            a, a:visited {
                color: #000;
            }   
        }

        a:hover {
            text-decoration: none;
        }

&.navrollo-enter-active,
&.navrollo-leave-active {
  transition: all 1s ;
}

&.navrollo-enter-to {

  top: 0;
}

&.navrollo-leave {
  
 top: 0;
}

&.navrollo-enter {
  
  top: -100vh;
}

&.navrollo-leave-to {

  top: -100vh;
}

    }
   
}


</style>
