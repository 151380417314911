<template>
    <div id="footer" :class="{'text-white': textWhite}">
        &copy; 2021 Nikolai Gust <span @click="$store.commit('impressumOn')" class="impressum-link">Impressum</span> 
    </div>
</template>

<script>
export default {
    name: 'Footer',
    components: {

    },
    data () {
        return {
  
        }
    },
    computed: {
        textWhite: function () {
            if (this.$route.path =='/contact') {
                return true
            } else {
                return false
            }
        }
    }
}
</script>

<style lang="scss">

 #footer {
    position: fixed;
    left: 20px;
    bottom: 30px;
    font-family: 'LeagueGothic';
    font-style: italic;
    z-index: 1050;

     @media screen and (max-width: 768px) {
        left: auto;
        right: 20px;
        bottom: 20px;
    }

     .impressum-link {
        cursor: pointer;
        margin-left: 20px;
     }
 }
</style>