import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    mousePressed: 0,
    navOpen: false,
    windowWidth: window.innerWidth,
    impressum: false
  },
  getters: {
    mousePressed: state => state.mousePressed,
    routerTo: state => state.routerTo,
    navOpen: state => state.navOpen,
    windowWidth: state => state.windowWidth,
    impressum: state => state.impressum
  },
  mutations: {
    setMouse (state, mouse) {
      state.mousePressed = mouse
    },
    setOpen (state, open ) {
      state.navOpen = open
    },
    impressumOn (state) {
      state.impressum = true
    },
    impressumOff (state) {
      state.impressum = false
    },
  },
  actions: {

  },
  modules: {
  }
})
