<template>

<div>      
    <div class="nav-symbol kontakt" :class="{'mousedown': mousePressed}">
        <svg width="281px" height="140px" viewBox="0 0 281 140" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>Contact</title>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="01_Start" transform="translate(-453.000000, -884.000000)" fill="#000000" fill-rule="nonzero">
                    <g id="Hintergrundelemente" transform="translate(0.000000, -0.083832)">
                        <g id="Group" transform="translate(-243.000000, 0.000000)">
                            <router-link to="/contact" id="kontaktlink">      
                                <g id="Contact" transform="translate(696.000000, 884.083832)">
                                    <polygon points="140.5 0 281 140.71425 280.713 141 0.286 141 0 140.71425"></polygon>
                                </g>
                            </router-link>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </div>
    <div v-if="show" class="kontakt-nav navi">
        <p>Contact</p>
    </div>
</div>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Kontakt',
    components: {

    },
    data () {
        return {
          display: false
        }
    },
    computed: {
      ...mapGetters([
                'mousePressed'
            ]),
        show: function() {
            if ( this.display && !this.mousePressed ) {
                return true
            } else {
                return false
            }
        }
    },
    mounted() {  
        const link = document.querySelector('#kontaktlink')
        link.addEventListener('mouseover', () => {
            this.display = true
        })

        link.addEventListener('mouseleave', () => {
            this.display = false
        })
    },
    destroyed() {
 
    }
}
</script>

<style lang="scss">

.kontakt {
        position: fixed;
        bottom: 0;
        left: 32vw;
        z-index: 13;

        svg {
            height: 14vh;
            width: auto;
        }
    }

     .kontakt-nav {
    
    }

    a #kontakt:hover ~ .kontakt-nav {
        display: block;
    }

    a #kontakt.mousedown:hover ~ .kontakt-nav {
        display: none;
    }
</style>
