<template>
    <div class="header">
        <div id="logo" :class="{'volle-breite': volleBreite}">
          <h1>
            <router-link to="/" :class="{'text-white': textWhite}">  
              NG<!--<img src="../assets/Nikolai Gust Logo.png" alt="Nikolai Gust Illustration und Grafikdesign Logo">-->
            </router-link>
            <span v-if="$route.path =='/projekte'" class="text-rot sel-blau">Projects</span>
            <span v-if="$route.path =='/about'" class="text-weiss sel-rot">About</span>
            <span v-if="$route.path =='/contact'" class="text-weiss sel-gelb">Contact</span>
            <span v-if="$route.path =='/mark-gallery'" class="text-weiss sel-weiss">Gallery</span>
            <span v-if="$route.path =='/canvas'" class="text-weiss sel-weiss">Canvas</span>

          </h1>
        </div><!--#logo-->
        <transition name="bout" appear>
          <MarkNav v-if="$route.path !=='/canvas' &&  $route.path !=='/mark-gallery'"/>
        </transition>
        <transition name="tout" appear>
          <Kontakt v-if="$route.path !=='/contact'"/>
        </transition>
        <transition name="lout">
          <AboutNav v-if="$route.path !=='/about'"/>
        </transition>
      
        <transition name="rout">
            <Kreis v-if="$route.path !=='/projekte'"/>
        </transition>
       
    </div><!--.header (root)-->
    
</template>

<script>
import Kreis from '@/components/Kreis.vue'
import MarkNav from '@/components/MarkNav.vue'
import Kontakt from '@/components/Kontakt.vue'
import AboutNav from '@/components/AboutNav.vue'

export default {
  name: 'Header',
    components: {
      Kreis,
      MarkNav,
      Kontakt,
      AboutNav
    },
    data () {
    return {
        name: ''
    }
  },
    computed: {
        textWhite: function () {
            if (this.$route.path =='/contact') {
                return true
            } else {
                return false
            }
        },
        volleBreite: function () {
          if (this.$route.path =='/leave-your-mark') {
                return true
            } else {
                return false
            }
        }
    }
}
</script>

<style lang="scss">

.header {
  font-family: 'LeagueGothic', sans-serif;
  font-style: italic;
}

#logo {
  z-index: 1101;
  position: absolute;
  top: 20px;
  left: 20px;
 
  h1 {
    font-size: 11vh;
    line-height: 11vh;
    color: #000;
    text-transform: uppercase;
    display: inline-block;
    padding-right: 5%;
    width: 50vw;

    span {
      margin-left: 0.1em;

      @media screen and (max-width: 767px) {
        margin-left: 0;
      }
      
    }

    a {
      color: #000;
      &:hover {
        text-decoration: none;
      }
    }
  }
   
}
   
</style>